<template>
    <div>

  
        <infoHeader :content_name="'AGV设备检查'" />

        <el-form ref="form" label-width="100px">
        <el-form-item label="初始AGV状态">
            <el-select v-model="agv_status" placeholder="请选择">
            <el-option
                v-for="item in agv_status_dict"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
            </el-select>
        </el-form-item>
        </el-form>
        <el-divider></el-divider>

        <el-form ref="form" :model="agv_cheack" label-width="80px">
            <el-form-item label="盖板检查">
                <el-input v-model="agv_cheack.cover"></el-input>
            </el-form-item>
        </el-form>

        <el-divider></el-divider>

        <el-form ref="form" label-width="100px">
        <el-button type="primary" @click="sumbitAdd">确认无误，提交检查表</el-button>
        </el-form>
    </div>
  </template>
  
  <script>
  // 载入接口
  import { agv_create } from "@/api/manage/operation/agv/agv.js";
  
  // 载入方法
  // import { 方法名 } from "@/utils/js文件名";

  export default {
    name: "start",
    data() {
      return {
        agv_status_dict: [
          {
            value: 0,
            label: "装配中",
          },
          {
            value: 1,
            label: "待使用",
          },
          {
            value: 2,
            label: "服役中",
          },
          {
            value: 3,
            label: "已暂停使用",
          },
          {
            value: 4,
            label: "已销毁",
          },
        ],
        agv_status: null,
        agv_cheack:{
            cover: ''
        }
      };
    },
    components: {

    },
    created() {},
    methods: {
      sumbitAdd() {
        //使用前记得定义一个函数 并把以下代码放入
  
        agv_create({
          // account: this.account 表示你要向这个接口发送一个名为account的数据 this.account是取你data内定义的account变量的值
          product_id: this.product_pitch_item.id,
          manufacturer_id: this.manufacturer_pitch_item.id,
          agv_status: this.agv_status,
        }).then((res) => {
          // 200 是接口返回的状态 如果接口正常返回数据给你会携带 200 非 200一般是失败 通过以下语句判断是否获取成功
          if (res.code == 200) {
            // 成功获取后要做的事情
            this.$message.success("新增成功")
          } else {
            // 获取失败后要做的事情
            this.$message.info("新增失败")
          }
        });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  